.data_header {
  align-items: center;
  min-height: 80px !important;
}
.tab-pane-content .MuiBox-root {
  padding: 0 !important;
}
.table-fit.table-custom-height,
.table-fit.table-custom-height
  .overflow-auto1
  > .ht_master:first-child
  > .wtHolder {
  height: calc(100vh - 260px) !important;
}
.home-content .MuiBox-root {
  padding-top: 0 !important;
}

.handsontable input,
.handsontable textarea {
  min-height: 16px !important;
}

.bs-popover-auto,
.my-popover {
  left: -169px !important;
}
.bs-popover-auto .arrow,
.my-popover .arrow {
  left: 90%;
}

@media screen and (max-width: 1199px) {
  .data_header {
    flex-direction: column;
    align-self: start;
  }
}
@media screen and (max-width: 767px) {
  .pagination-mobile,
  .pagination-block {
    flex-direction: column;
  }
}

/* Move the map controls to the right */
.gm-style .gm-style-mtc {
  right: 0 !important;
}

/* Adjust the position of the satellite button */
.gm-style .gm-style-mtc div[title="Toggle between map and satellite"] {
  right: 40px !important;
}

.z-1 {
  z-index: 10;
}

.handsontableEditor .wtHolder {
  background: white;
  padding-right: 0;
}

.htNoWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: to show ellipsis for overflowing text */
}
